import { Flex, Heading, Spinner, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import CustomerSettingsLayout from "./index"
import renderHTML from "react-render-html"
import { API_ENDPOINT } from "../../../constants/endpoints"

export default function TermsAndConditions() {
  const [content, setContent] = useState("")

  useEffect(() => {
    fetch(API_ENDPOINT + "terms-and-condition/")
      .then(res => res.json())
      .then(data => {
        setContent(data)
      })
  }, [])

  return (
    <CustomerSettingsLayout>
      <Flex
        direction="column"
        gap={4}
        jusitfyContent={"center"}
        alignItems={"center"}
      >
        {content ? renderHTML(content?.[0]?.body || "") : <Spinner />}
      </Flex>
    </CustomerSettingsLayout>
  )
}
