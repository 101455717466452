import { DeleteIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Input,
  Textarea,
  useToast
} from "@chakra-ui/react"
import React, { useEffect, useState, useMemo } from "react"
import { BiLeftArrowAlt } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux"
import help from "../../assets/svg/help.svg"
import ConfirmDialog from "../../components/Dialog/ConfirmDialog"
import {
  closeCart,
  emptyCart,
  removeDishFromCart,
  removeOrdersByRestaurant,
  setDeliveryAddress
} from "../../reducers/cart"
import { useApi } from "../../services/fasterDriver"
import { address2Text } from "../../utils/data"
import AddPayment from "./AddPayment"
import { DELIVERY_FEE } from "constants/orders"
import { useHistory } from "react-router-dom"

const STEP = {
  CART: 0,
  PAYMENT: 1,
  ADD_CARD: 2
}
export default function Cart() {
  const dispatch = useDispatch()
  const { visible, activeRestaurant, orderMap, deliveryAddress } = useSelector(
    state => state.cart
  )
  // const [deliveryAddress2, setDeliveryAddress2] = useState(deliveryAddress)
  const [tip, setTip] = useState("")
  const [paymentTotal, setPaymentTotal] = useState(0)
  const order = orderMap[activeRestaurant?.id] || {}
  const orderPrice = useMemo(
    () =>
      Math.round(
        Object.keys(order).reduce((acc, dishId) => {
          const item = order[dishId]
          return acc + (item?.details?.price || 0) * (item?.quantity || 0)
        }, 0) * 100
      ) / 100,
    [order]
  )

  const taxAmount = useMemo(
    () =>
      Math.round((orderPrice * (activeRestaurant?.tax_rate || 0) * 100) / 100) /
      100,
    [orderPrice, activeRestaurant?.tax_rate]
  )

  const fee = useMemo(() => (orderPrice > 0 ? DELIVERY_FEE : 0), [orderPrice])
  const total = useMemo(
    () => Math.round((orderPrice + taxAmount + fee + Number(tip)) * 100) / 100,
    [orderPrice, taxAmount, fee, tip]
  )

  const [step, setStep] = useState(STEP.CART)

  const [payments, setPayments] = useState([])
  const [activePaymentId, setActivePaymentId] = useState(null)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [orderId, setOrderId] = useState(null)
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [showTip, setShowTip] = useState(false)
  const [tipValue, setTipValue] = useState("")
  const [showOtherAddress, setShowOtherAddress] = useState(false)

  const { user } = useSelector(state => state.auth)
  const profile = user[user?.type?.toLowerCase()] || {}


  const [pending, setPending] = useState(false)
  const history = useHistory();

  const api = useApi()
  const toast = useToast()

  const fetchPayments = () => {
    api.getMyPaymentMethodsApi().then(({ data, ok }) => {

      if (ok) {
        setPayments(data?.data || [])
      }
    })
  }

  useEffect(() => {
    fetchPayments()
  }, [])

  useEffect(() => {
    if (step === STEP.PAYMENT)
      fetchPayments()
  }, [step])

  const handleTipChange = e => {
    setTipValue(e.target.value)
  }

  const close = () => {
    dispatch(closeCart())
  }

  const tipToDriver = () => {
    setTip(parseFloat(tipValue)?.toFixed(2))
    setShowTip(false)
  }

  const enableTip = () => {
    setShowTip(true)
  }

  const disableTip = () => {
    setShowTip(false)
    setTipValue("")
    setTip("")
  }

  const _renderCart = () => {
    return (
      <Flex w={"100%"} h={"100%"} direction={"column"} overflowY={"auto"}>
        <Flex fontSize={24} fontWeight={600} p={4}>
          {activeRestaurant?.name}
        </Flex>
        <Flex p={2} gap={4}>
          <Button
            variant={"outline"}
            fontSize={14}
            borderRadius={20}
            borderColor={"black"}
            onClick={close}
          >
            Add Items
          </Button>
          {/*<Button variant={'outline'} fontSize={14} borderRadius={20} borderColor={'black'}>Group Order</Button>*/}
        </Flex>
        <Flex w={"100%"} bg={"gray.300"} px={4} py={2}>
          Items
        </Flex>
        <Box p={2}>
          {Object.keys(order).map(dishId => {
            const item = order[dishId]
            if (item?.details?.name) {
              return (
                <Flex
                  key={"dish-" + dishId}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  px={2}
                >
                  <Flex gap={2} w={"80%"}>
                    <Image
                      src={item?.details?.image_1 || help}
                      alt={item?.details?.name}
                      mb={2}
                      w={14}
                      h={14}
                    />
                    <Flex direction={"column"} w={"74%"}>
                      <Flex gap={1}>
                        {item?.quantity > 1 && (
                          <Box color={"primary.500"}>{item?.quantity}x</Box>
                        )}
                        {item?.details?.name}
                      </Flex>
                      <Box
                        noOfLines={2}
                        fontSize={14}
                        color={"gray.900"}
                        fontWeight={300}
                      >
                        {item?.details?.description}
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex gap={2} alignItems={"center"}>
                    <Box>
                      ${" "}
                      {Number(item?.quantity * item?.details?.price).toFixed(2)}
                    </Box>
                    <DeleteIcon
                      color={"red.400"}
                      cursor={"pointer"}
                      onClick={() => {
                        dispatch(
                          removeDishFromCart({
                            restaurantId: activeRestaurant?.id,
                            dishId
                          })
                        )
                      }}
                    />
                  </Flex>
                </Flex>
              )
            } else {
              return null
            }
          })}
        </Box>
        <Flex direction={"column"} gap={1} px={6}>
          <Flex justifyContent={"space-between"}>
            <Box>Price</Box>
            <Box>$ {parseFloat(orderPrice || 0)?.toFixed(2)}</Box>
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Box>Tax rate ( {activeRestaurant?.tax_rate || 0}%) </Box>
            <Box>$ {parseFloat(taxAmount || 0)?.toFixed(2)}</Box>
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Box>Fee</Box>
            <Box>$ {parseFloat(fee || 0)?.toFixed(2)}</Box>
          </Flex>
          <Flex direction={"column"}>
            <Flex justifyContent={"space-between"}>
              <Box
                color="#0093D9"
                fontSize="14px"
                fontWeight="600"
                onClick={enableTip}
              >
                Tip your driver +
              </Box>
              <Box>{tip > 0 ? `$${tip}` : ""}</Box>
            </Flex>
            {showTip && (
              <Flex
                direction="row"
                alignItems="center"
                justifyContent={"space-between"}
                gap={1}
              >
                <Flex direction="row" alignItems="stretch">
                  <Flex
                    textAlign={"center"}
                    w="40px"
                    bg={"gray.100"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={"var(--chakra-radii-md)"}
                    borderTopRightRadius={0}
                    borderBottomRightRadius={0}
                  >
                    $
                  </Flex>
                  <Input
                    type={"number"}
                    min={0}
                    w="70px"
                    bg={"gray.100"}
                    border={"none"}
                    value={tipValue}
                    placeholder="1.00"
                    onChange={handleTipChange}
                    borderTopLeftRadius={0}
                    borderBottomLeftRadius={0}
                  />
                </Flex>
                <Flex direction="row" alignItems="center" gap={1}>
                  <Button
                    variant={"outline"}
                    fontSize={14}
                    borderRadius={20}
                    borderColor={"black"}
                    onClick={disableTip}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme={"primary"}
                    fontSize={14}
                    borderRadius={20}
                    onClick={tipToDriver}
                    disabled={tipValue === "" || tipValue <= 0}
                  >
                    Tip
                  </Button>
                </Flex>
              </Flex>
            )}
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Box>Total</Box>
            <Box>$ {parseFloat(total || 0)?.toFixed(2)}</Box>
          </Flex>
        </Flex>
        <Flex w={"100%"} bg={"gray.300"} px={4} py={2} my={4}>
          Address
        </Flex>

        {profile?.addresses?.length > 0 || deliveryAddress ? <>
          <Flex px={4}>
            <Box w={"100%"} p={2} color={"gray.600"} fontSize={14}>
              {deliveryAddress ? address2Text(deliveryAddress)
                :
                'Select an Address'}
            </Box>


            <Button color={"white"} bg={"black"} h={8} fontSize={12}
              _hover={{ bg: '#505052' }}
              onClick={() => setShowOtherAddress(!showOtherAddress)}
            >
              {showOtherAddress ? 'Continue' : 'Other'}
            </Button>

                
          </Flex>
          {showOtherAddress && <Box bg={"gray.100"} mx={5} p={3} cursor="pointer">
            {profile?.addresses?.map((it, index) => (
              <Flex px={4} _hover={{ bg: '#ffffff' }} key={`cart-address-${index}`}>
                <Box w={"100%"} p={2} color={"gray.600"} fontSize={14}
                  onClick={() => {
                    dispatch(setDeliveryAddress(it));
                    setShowOtherAddress(false)
                  }}
                >
                  {address2Text(it)}
                </Box>
              </Flex>
            ))
            }
          </Box>}
        </> :
          <>
            <Flex px={4}>
              <Box w={"100%"} p={2} color={"gray.600"} fontSize={14}>
                {'--Address records not found--'}
              </Box>

              <Button color={"white"} bg={"black"} h={8} fontSize={12}
                _hover={{ bg: '#505052' }}
                onClick={() => {
                  close()
                  history.push('/customer/settings')
                }}
              >
                {'Add Address'}
              </Button>


            </Flex>
          </>
        }

        <Flex direction={"column"} gap={1} px={4} my={2}>
          <Box>SPECIAL INSTRUCTIONS</Box>
          <Textarea
            resize={"none"}
            bg={"gray.100"}
            border={"none"}
            value={specialInstructions}
            onChange={e => setSpecialInstructions(e.target.value)}
          />
        </Flex>

        <Flex justifyContent={"center"} my={4}>
          <Button
            w={"60%"}
            colorScheme={"primary"}
            onClick={async () => {
              if (!deliveryAddress) {
                toast({
                  title: "Error",
                  description: "Please select delivery address",
                  status: "error"
                })
                return
              }

              let _deliveryAddress = deliveryAddress
              let _data = {
                restaurant: activeRestaurant?.id,
                address: "",
                special_instructions: specialInstructions,
                tip
              }

              if (!_deliveryAddress.id) {
                try {
                  const { data, ok } = await api.addAddressApi({
                    customer: {
                      addresses: [
                        {
                          street: _deliveryAddress.street,
                          city: _deliveryAddress.city,
                          state: _deliveryAddress.state,
                          zip_code: _deliveryAddress.zip_code
                        }
                      ]
                    }
                  })
                  if (ok) _deliveryAddress = data
                } catch (e) {
                  // console.log("addAddressApi", e)
                  return toast({
                    title: "Error",
                    description:
                      "We could not use your address. Please try again with other address.",
                    status: "error"
                  })
                }
                _data.address =
                  _deliveryAddress?.customer?.addresses?.[
                    _deliveryAddress?.customer?.addresses?.length - 1
                  ]?.id
              } else {
                _data.address = _deliveryAddress.id
              }

              let count = 0
              Object.keys(order).forEach(dishId => {
                const item = order[dishId]
                if (item?.details?.name) {
                  _data[`dishes[${count}]dish`] = dishId
                  _data[`dishes[${count}]quantity`] = item?.quantity
                  count++
                }
              })
              if (count === 0) {
                toast({
                  title: "Error",
                  description: "Please add items to cart",
                  status: "error"
                })
                return
              }

              setPending(true)
              api
                .createOrderApi(_data)
                .then(({ data, ok }) => {
                  console.log("createOrder", data)
                  if (ok) {
                    setOrderId(data?.id)
                    setStep(STEP.PAYMENT)
                    setPaymentTotal(total)
                    dispatch(emptyCart())
                    setTip("")
                  } else {
                    toast({
                      title: "Error",
                      description: data,
                      status: "error",
                      duration: 3000,
                      isClosable: true
                    })
                  }
                })
                .finally(() => {
                  setPending(false)
                })
            }}
            h={14}
            isLoading={pending}
          >
            Confirm
          </Button>
        </Flex>
      </Flex>
    )
  }

  const _renderPayment = () => {
    return (
      <Flex
        p={4}
        direction={"column"}
        justifyContent={"space-between"}
        h={"100%"}
      >
        <Flex direction={"column"} h={"80%"}>
          <Flex gap={2} alignItems={"center"}>
            <Flex
              bg={"black"}
              color={"white"}
              h={6}
              w={6}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"50%"}
              cursor={"pointer"}
              onClick={() => setStep(STEP.CART)}
            >
              <BiLeftArrowAlt />
            </Flex>
            <Box fontSize={18} fontWeight={600}>
              Payment
            </Box>
          </Flex>
          <Flex p={8} direction={"column"} gap={6}>
            <Flex justifyContent={"space-between"} w={"100%"}>
              <Box>Total</Box>
              <Box>${paymentTotal?.toFixed(2)}</Box>
            </Flex>
            <Flex justifyContent={"center"} direction={"column"} gap={4}>
              {payments.map(payment => (
                <Flex
                  key={payment.id}
                  borderRadius={10}
                  bg={"gray.100"}
                  gap={2}
                  py={2}
                  px={4}
                  direction={"column"}
                  cursor={"pointer"}
                  onClick={() => setActivePaymentId(payment.id)}
                  border={
                    activePaymentId === payment.id ? "1px solid black" : "none"
                  }
                >
                  <Flex justifyContent={"space-between"}>
                    <Box textTransform={"capitalize"}>
                      {payment?.card?.brand} card
                    </Box>
                  </Flex>
                  <Flex gap={2} alignItems={"center"}>
                    <Image
                      src={payment?.card?.image || help}
                      alt={payment?.card?.brand}
                      w={8}
                      h={8}
                    />
                    <Box>**** **** **** {payment?.card?.last4}</Box>
                  </Flex>
                </Flex>
              ))}
            </Flex>
            <Button
              variant={"outline"}
              h={14}
              onClick={() => setStep(STEP.ADD_CARD)}
            >
              + Add New
            </Button>
          </Flex>
        </Flex>

        <Flex justifyContent={"center"} gap={2}>
          <Button
            w={"30%"}
            bg={"red.600"}
            color={"white"}
            h={14}
            disabled={activePaymentId === null}
            onClick={() => {
              setAlertTitle("Remove Card")
              setAlertMessage("Are you sure you want to remove this card?")
            }}
          >
            Remove
          </Button>
          <Button
            w={"60%"}
            bg={"primary.500"}
            color={"white"}
            h={14}
            disabled={activePaymentId === null}
            onClick={() => {
              api
                .payForOrderApi({
                  payment_method: activePaymentId,
                  order: orderId
                })
                .then(({ data, ok }) => {
                  console.log("payForOrderApi", data)
                  if (ok) {
                    dispatch(removeOrdersByRestaurant(activeRestaurant?.id))
                    dispatch(closeCart())
                    setOrderId(null)
                    setStep(STEP.CART)
                    toast({
                      title: "Success",
                      description: "Your order has been placed",
                      status: "success"
                    })
                  } else {
                    toast({
                      title: "Error",
                      description:
                        (typeof data === "string" ? data : data?.detail) ||
                        "Something went wrong",
                      status: "error",
                      duration: 3000,
                      isClosable: true
                    })
                  }
                })
            }}
          >
            Pay
          </Button>
        </Flex>
      </Flex>
    )
  }

  const _renderAddCard = () => {
    return (
      <AddPayment
        onFinished={() => {
          setStep(STEP.PAYMENT)
        }}
      />
    )
  }
  const renderContent = () => {
    switch (step) {
      case STEP.CART:
        return _renderCart()
      case STEP.PAYMENT:
        return _renderPayment()
      case STEP.ADD_CARD:
        return _renderAddCard()
      default:
        break
    }
    return null
  }

  return (
    <Drawer isOpen={visible} placement="right" onClose={close} size={"md"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {renderContent()}
      </DrawerContent>
      <ConfirmDialog
        isOpen={alertTitle !== ""}
        onClose={() => setAlertTitle("")}
        title={alertTitle}
        message={alertMessage}
        onOk={() => {
          api.removePaymentMethodApi(activePaymentId).then(() => {
            setAlertTitle("")
            setAlertMessage("")
            setActivePaymentId(null)
            fetchPayments()
          })
        }}
      />
    </Drawer>
  )
}
