const Facebook = 'https://www.facebook.com/FasterDriversDeliveryServiceLLC'
const Instagram = 'https://www.instagram.com/fasterdrivers/?hl=en'
const Youtube = 'https://www.youtube.com/channel/UC6IdStEFCY_rtkH6Qg0l_Ig'
const LinkedIn = 'https://www.linkedin.com/in/fasterdrivers/'

export default {
  Facebook,
  Instagram,
  Youtube,
  LinkedIn,
}
