// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons"
// Chakra Imports
import {
  Badge,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Spinner
} from "@chakra-ui/react"
// Assets
// Custom Icons
import { CartIcon } from "components/Icons/Icons"
// Custom Components
import SidebarResponsive from "components/Sidebar/SidebarResponsive"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { USER_TYPES } from "../../constants/users"
import { openCart } from "../../reducers/cart"
import { setKeyword } from "../../reducers/ui"
import { ItemContent } from "components/Menu/ItemContent"
import { useApi } from "../../services/fasterDriver"
import { setNotifications } from "../../reducers/ui"
import moment from "moment"
import { useLocation, matchPath } from "react-router-dom"

const getTotalOrderCount = (orderMap, restaurantId) => {
  let res = 0

  const resCart = orderMap?.[restaurantId] || {}
  Object.keys(resCart).forEach(dishId => {
    if (resCart[dishId]?.quantity) res += resCart[dishId].quantity
  })

  return res
}
export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props
  const api = useApi()
  const location = useLocation()

  const { keyword, notifications } = useSelector(state => state.ui)
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)
  const { user } = useSelector(state => state.auth)
  const [loading, setLoading] = useState()

  const [orderCount, setOrderCount] = React.useState(
    getTotalOrderCount(cart.orderMap, cart?.activeRestaurant?.id)
  )

  useEffect(() => {
    setOrderCount(getTotalOrderCount(cart.orderMap, cart?.activeRestaurant?.id))
  }, [cart.lastUpdated, cart?.activeRestaurant?.id])

  const fetchNotifications = () => {
    setLoading(true)
    api
      .getNotifications()
      .then(({ data, ok }) => {
        // console.log('restaurant22', data)
        if (ok) {
          dispatch(setNotifications(data?.results))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Chakra Color Mode
  let mainTeal = useColorModeValue("#0093D9", "#0093D9")
  let inputBg = useColorModeValue("white", "gray.800")
  let mainText = useColorModeValue("gray.700", "gray.200")

  if (secondary) {
    mainText = "white"
  }
  const settingsRef = React.useRef()
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "100%" }}
      alignItems="center"
      gap="1rem"
      justifyContent={{
        base: "flex-end"
      }}
    >
      {(matchPath(location?.pathname, { path: "/customer/home" }) ||
        matchPath(location?.pathname, { path: "/admin/dashboard" })) && (
        <InputGroup
          cursor="pointer"
          bg={inputBg}
          borderRadius="15px"
          w={{
            sm: "128px",
            md: "354px"
          }}
          me={{ sm: "auto", md: "20px" }}
          _focus={{
            borderColor: { mainTeal }
          }}
          _active={{
            borderColor: { mainTeal }
          }}
          display={{ base: "none", md: "flex" }}
        >
          <InputLeftElement
            children={
              <IconButton
                bg="inherit"
                borderRadius="inherit"
                _hover="none"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent"
                }}
                _focus={{
                  boxShadow: "none"
                }}
                icon={<SearchIcon color={"gray.400"} w="24px" h="24px" />}
              ></IconButton>
            }
          />
          <Input
            fontSize="xs"
            py="15px"
            color={mainText}
            placeholder="Search..."
            borderRadius="inherit"
            value={keyword}
            onChange={e => dispatch(setKeyword(e.target.value))}
          />
        </InputGroup>
      )}

      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        {...rest}
      />

      <Menu>
        <MenuButton
          w={"52px"}
          h={"52px"}
          borderRadius={"10px"}
          borderWidth={1}
          ml={"10px"}
          onClick={fetchNotifications}
        >
          <BellIcon color={"gray.400"} w="23px" h="23px" />
        </MenuButton>
        <MenuList p="16px 8px" maxHeight="400px" overflowY="auto">
          <Flex flexDirection="column">
            {loading ? (
              <MenuItem borderRadius="8px">
                <Spinner />
              </MenuItem>
            ) : notifications?.length > 0 ? (
              notifications?.map((item, index) => (
                <MenuItem borderRadius="8px" mb="10px">
                  <ItemContent
                    time={moment?.utc(item?.created)?.local().fromNow()}
                    info={item?.title}
                    boldInfo=""
                    aName={
                      item?.object_details?.restaurant?.name ||
                      item?.receiver?.name ||
                      item?.receiver?.email
                    }
                    aSrc={BellIcon}
                  />
                </MenuItem>
              ))
            ) : (
              <MenuItem borderRadius="8px">No notifications</MenuItem>
            )}
          </Flex>
        </MenuList>
      </Menu>
      {user?.type === USER_TYPES.CUSTOMER && (
        <Button
          variant={"solid"}
          w={"52px"}
          h={"52px"}
          borderRadius={"10px"}
          bg={"#0093D9"}
          ml={"10px"}
          onClick={() => {
            dispatch(openCart())
          }}
        >
          <CartIcon
            ref={settingsRef}
            onClick={props.onOpen}
            color={"white"}
            w="23px"
            h="23px"
          />
          {orderCount > 0 && (
            <Badge
              position={"absolute"}
              top={0}
              right={0}
              variant={"solid"}
              colorScheme={"red"}
              borderRadius={"50%"}
            >
              {orderCount}
            </Badge>
          )}
        </Button>
      )}
    </Flex>
  )
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func
}
