import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Input
} from "@chakra-ui/react"
import React, { useEffect, useRef, useState } from "react"
import homeBanner from "../../assets/img/HomeBanner1.png"
import homeBannerPattern from "../../assets/svg/home-banner-pattern.svg"
import IconFacebook from "../../assets/svg/icon-facebook.svg"
import IconInstagram from "../../assets/svg/icon-instagram.svg"
import IconLinkedin from "../../assets/svg/icon-linkedin.svg"
import IconYouTube from "../../assets/svg/icon-youtube.svg"

import Socials from "../../constants/socials"
import { searchRestaurantApi } from "services/fasterDriver"
import { debounce } from "lodash"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { API_ENDPOINT } from "../../constants/endpoints";

function Banner() {
  // const [searchText, setSearchText] = useState("")
  const searchText = useRef("")
  const searchBoxRef = useRef(null)
  const [restaurantList, setRestaurantList] = useState([])
  // const restaurantList = useRef([])

  const handleSearch = debounce(() => {
    if(searchText.current !==""){
    searchRestaurantApi({ input: searchText.current })
      .then(({ ok, data }) => {
        if (ok) setRestaurantList(data)
      })
      .catch(error => console.log(error))
    }else setRestaurantList([])
  },300)

  const history = useHistory()

  

  const handleSelect = (restaurantName)=>{
    // if(restaurantName!=="") 
    searchBoxRef.current.value=restaurantName
    setRestaurantList([])
  }

  const [content, setContent] = React.useState("")
  useEffect(() => {
    fetch(API_ENDPOINT + "videos/")
      .then(res => res.json())
      .then(data => {
        
        if(data.length > 0){
          setContent(data)
        }
        
      })
  } , [])

  // useEffect(()=>{
  //   console.log("ref",searchText.current)
  //   handleSearch()
  // },[searchText.current])

  // useEffect(()=>{
  //   handleSearch()
  // },[searchText])


  return (
    <Box
      w="100%"
      display={"block"}
      h={"100%"}
      overflow={"hidden"}
      position={"relative"}
    >
      <Flex
        w="100%"
        h={{ sm: "initial", md: "95vh" }}
        bg={"primary.500"}
        pt={{ base: "10%", md: "0%" }}
        px={{ base: "3%", md: "6%", xl: "10%" }}
        flexDir={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        position={"relative"}
      >
        
        <Flex
          px={{ base: "0px", md: "5%" }}
          flexWrap={"wrap"}
          w={{ base: "100%", md: "40%" }}
          justifyContent={"flex-start"}
          alignItems={"space-between"}
          gap={{
            base: "10",
            md: "81"
          }}
        >
          
          <Flex flexDir={"column"} gap={{base:"10", md:"5"}}>
            
            <Heading as={"h2"} color="white" size={"2xl"} >
              Lower Costs,
              <br />
              Higher Earnings
              <br />
              Faster Drivers
            </Heading>
          

           

           
          </Flex>
          {/* </div> */}

          
          <Flex gap={"4"} zIndex={{ base: "1000", "2xl": "1" }}>
            <a href={Socials.Facebook} target={"_blank"}>
              <Center
                bg={"primary.600"}
                w={"48px"}
                h={"48px"}
                borderRadius={"50%"}
              >
                <Image src={IconFacebook} alt={"facebook"} />
              </Center>
            </a>
            <a href={Socials.LinkedIn} target={"_blank"}>
              <Center
                bg={"primary.600"}
                w={"48px"}
                h={"48px"}
                borderRadius={"50%"}
              >
                <Image src={IconLinkedin} alt={"linkedin"} />
              </Center>
            </a>
            <a href={Socials.Instagram} target={"_blank"}>
              <Center
                bg={"primary.600"}
                w={"48px"}
                h={"48px"}
                borderRadius={"50%"}
              >
                <Image src={IconInstagram} alt={"instagram"} />
              </Center>
            </a>
            <a href={Socials.Youtube} target={"_blank"}>
              <Center
                bg={"primary.600"}
                w={"48px"}
                h={"48px"}
                borderRadius={"50%"}
              >
                <Image
                  src={IconYouTube}
                  alt={"youtube"}
                  w={"42px"}
                  h={"42px"}
                />
              </Center>
            </a>
          </Flex>
          
        </Flex>
        {/* </span> */}
        {content.length > 0 ? <Flex w={{ base: "100%", md: "60%"}} justifyContent={"flex-end"}>
          <div style={{borderColor:'white',borderWidth:8,width:'100%',marginTop:25,marginBottom:25,zIndex:9999 }}>
            <iframe width="100%" height="400" src={content[0]?.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </Flex>   : null}       
        
      </Flex>

      <Box pos={"absolute"} right={"0"} top={"8%"} h={"120%"}>
        <Image
          src={homeBannerPattern}
          h={"100%"}
          fit={"cover"}
          objectPosition={"right"}
        />
      </Box>
    </Box>
  )
}

export default Banner
