import {Box,} from "@chakra-ui/react";

import React, { useEffect } from "react";
import Apps from "./Apps";
// Assets
import Banner from './Banner'
import Categories from "./Categories"
import Cuisines from "./Cuisines";
import Restaurants from "./Restaurants"
import { set } from "lodash";



function Index() {

  return (
    <>
    <Box
      w='100%'
      mx='auto'
    >
      <Banner/>
      <Categories/>
      <Restaurants/>
      <Cuisines/>
      <Apps/>
    </Box>
    </>
  );
}

export default Index;
