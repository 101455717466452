export const USER_TYPES = {
  NONE: 'None',
  RESTAURANT: 'Restaurant',
  CUSTOMER: 'Customer',
  DRIVER: 'Driver',
  ADMIN: ''
}

export default {
  TYPES: USER_TYPES,
}
