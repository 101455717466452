/*eslint-disable*/
import {
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  Text,
  useToast
} from "@chakra-ui/react"
import IconApplePay from "assets/svg/icon-apple-pay.svg"
import IconFacebook from "assets/svg/icon-facebook.svg"
import IconInstagram from "assets/svg/icon-instagram.svg"
import IconMasterCard from "assets/svg/icon-mastercard.svg"
import IconTelegram from "assets/svg/icon-telegram.svg"
import IconLinkedin from "assets/svg/icon-linkedin.svg"
import IconYouTube from "assets/svg/icon-youtube.svg"
import IconVisa from "assets/svg/icon-visa.svg"
import logo from "assets/svg/logo.svg"
import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"
import Socials from "../../constants/socials"
import { object, string } from "yup"
import { newsLetterApi } from "services/fasterDriver"

const newsLetterSchema = object().shape({
  email: string().email(`Invalid email`).required(`Email is required`)
})

export default function Footer(props) {
  // const linkTeal = useColorModeValue("teal.400", "red.200");=
  const [email, setEmail] = React.useState("")
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)

  function handleSubscribe() {
    setLoading(true)
    newsLetterSchema
      .validate({ email })
      .then(() => {
        newsLetterApi({ email, is_active: true })
          .then(({ data, ok }) => {
            if (ok) {
              toast({
                title: `Success`,
                description: `Thank you for joining our newsletter community`,
                status: `success`
              })
              setEmail("")
            } else {
              toast({
                title: `Error`,
                description: data?.email || `Something went wrong`,
                status: `error`
              })
            }
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch(err => {
        setLoading(false)
        toast({
          title: "Error",
          description: "Invalid email.",
          status: "error",
          duration: 5000,
          isClosable: true
        })
      })
  }
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      w={"100%"}
      bg={"white"}
      py={{ base: "5%", md: "5%" }}
      px={{ base: "3%", md: "6%", xl: "10%" }}
      color={"black"}
      gap={"5"}
    >
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(3, 1fr)"
        }}
        gap={6}
        w={"100%"}
        pb={"5%"}
      >
        <GridItem>
          <Flex
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"3"}
            w={{
              base: "100%",
              md: "160px"
            }}
          >
            <Image src={logo} alt={"logo"} />
            <Flex gap={"4"} zIndex={{ base: "1000", "2xl": "1" }}>
              <a href={Socials.Facebook} target={"_blank"}>
                <Center
                  bg={"primary.500"}
                  w={"48px"}
                  h={"48px"}
                  borderRadius={"50%"}
                >
                  <Image src={IconFacebook} alt={"facebook"} />
                </Center>
              </a>
              <a href={Socials.LinkedIn} target={"_blank"}>
                <Center
                  bg={"primary.500"}
                  w={"48px"}
                  h={"48px"}
                  borderRadius={"50%"}
                >
                  <Image src={IconLinkedin} alt={"twitter"} />
                </Center>
              </a>
              <a href={Socials.Instagram} target={"_blank"}>
                <Center
                  bg={"primary.500"}
                  w={"48px"}
                  h={"48px"}
                  borderRadius={"50%"}
                >
                  <Image src={IconInstagram} alt={"instagram"} />
                </Center>
              </a>
              <a href={Socials.Youtube} target={"_blank"}>
                <Center
                  bg={"primary.500"}
                  w={"48px"}
                  h={"48px"}
                  borderRadius={"50%"}
                >
                  <Image
                    src={IconYouTube}
                    alt={"youtube"}
                    w={"42px"}
                    h={"42px"}
                  />
                </Center>
              </a>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex
            flexDir={"column"}
            alignItems={{
              base: "center",
              md: "flex-start"
            }}
            gap={"3"}
          >
            <NavLink to={"/faq"}>FAQ</NavLink>
            <NavLink to={"/about-us"}>About Us</NavLink>
            <NavLink to={"/contact-us"}>Contact Us</NavLink>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex flexDir={"column"} gap={"3"}>
            <Text>SUBSCRIBE</Text>
            <InputGroup>
              <Input
                type={"email"}
                placeholder={"Your email"}
                bg={"white"}
                color={"black"}
                borderRadius={"10px"}
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
              <InputRightElement>
                <Image
                  src={IconTelegram}
                  alt={"telegram"}
                  onClick={loading ? "" : handleSubscribe}
                  cursor={loading ? "not-allowed" : "pointer"}
                />
              </InputRightElement>
            </InputGroup>
          </Flex>
        </GridItem>
      </Grid>
      <Flex h={"1px"} bg={"gray.600"} />
      <Flex
        w={"100%"}
        justifyContent={"space-between"}
        pt={"3%"}
        flexDir={{
          base: "column",
          md: "row"
        }}
        gap={4}
        mb={{ base: "20px", xl: "0px" }}
      >
        <Flex
          w={{ base: "100%", md: "50%" }}
          gap={"4"}
          justifyContent={{
            base: "center",
            md: "flex-start"
          }}
          alignItems={"center"}
        >
          <Image src={IconVisa} alt={"visa"} w={"28px"} h={"20px"} />
          <Image
            src={IconMasterCard}
            alt={"mastercard"}
            w={"28px"}
            h={"20px"}
          />
          <Image src={IconApplePay} alt={"apple-pay"} w={"28px"} h={"20px"} />
        </Flex>
        <Flex
          w={{
            base: "100%",
            md: "50%"
          }}
          justifyContent={{
            base: "center",
            md: "flex-end"
          }}
          alignItems={{
            base: "center",
            md: "center"
          }}
          flexDir={{
            base: "column",
            md: "row"
          }}
        >
          <Link color="black" href="/terms-and-conditions">
            Terms and conditions
          </Link>

          <Link color="black" m={4} href="/privacy-policy">
            Privacy
          </Link>

          <Flex color="black" alignItems="center">
            &copy; {new Date().getFullYear()} &nbsp;
            <Text>Faster Drivers</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
