import { Flex, Spinner } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import renderHTML from "react-render-html"
import { API_ENDPOINT } from "../../constants/endpoints"
import { Heading } from "@chakra-ui/react"

export default function TermsAndConditions() {
  const [content, setContent] = useState("")

  useEffect(() => {
    fetch(API_ENDPOINT + "terms-and-condition/")
      .then(res => res.json())
      .then(data => {
        setContent(data)
      })
  }, [])

  return (
    <Flex bg={"white"} justifyContent={"center"} w={"100%"} p={10}>
      <Flex
        direction="column"
        gap={8}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Heading
          as="h1"
          size="4xl"
          noOfLines={1}
          textAlign="left"
          alignSelf={"flex-start"}
        >
          Terms and Conditions
        </Heading>
        {content ? renderHTML(content?.[0]?.body || "") : <Spinner />}
      </Flex>
    </Flex>
  )
}
