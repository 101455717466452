export const ORDER_STATUS = {
  Unpaid: "unpaid",
  Pending: "pending",
  Accepted: "accepted",
  Rejected: "rejected",
  DriverAssigned: "driver_assigned",
  Delivered: "delivered",
  InTransit: "in_transit",
  InProgress: "in_progress",
  Completed: "completed"
}

export const ORDER_STATUS_DISPLAY = {
  unpaid: "Unpaid",
  pending: "Pending",
  accepted: "Accepted",
  rejected: "Rejected",
  driver_assigned: "Driver Assigned",
  delivered: "Delivered",
  in_transit: "In Transit",
  in_progress: "In Progress",
  completed: "Completed"
}

export const DELIVERY_FEE = 5
